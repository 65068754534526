import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const Page = ({ data, location }) => {
    const page = data.ghostPage
    // console.log("page: ", page)
    // console.log("location: ", location)

    function createMarkup() {
        return {__html: page.html};
      }

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="website"
            />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
                <script src={"/js/post.js"} type="text/javascript" />
            </Helmet>
            <Layout isPostPage={true}>
                <article>
                    <div className={"section-featured " + (page.featured ? "is-featured-image" : "no-featured-image")}>
                        { page.feature_image ? 
                            <div className="featured-image" style={{"backgroundImage" : `url(${page.feature_image})`}}></div>
                            : null}
                        <div className="featured-wrap flex">
                            <div className="featured-content">
                                <h1 className="white">{page.title}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="section-post wrap">
                        <div className={"post-wrap" + (page.featured ? " no-image" : null)} dangerouslySetInnerHTML={createMarkup()}></div>   
                    </div>
                </article>
            </Layout>
        </>
    )
}

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default Page

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
    }
`
